/* eslint-disable */
import { Stack, Typography, IconButton, Menu, MenuItem, Box, Button, Checkbox, ListItemText } from '@mui/material';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { getFieldOptions, SocialHxActiveProps, SocialHxInActiveProps, MedsInActiveProps, MedsTradeProps, AllergiesInActiveProps } from "../utils/BinUtils";
import { FormControlLabel, Radio, RadioGroup, SelectChangeEvent } from "@mui/material";
import { useState, useEffect } from "react";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from '../state/reducers';
import { useSelector } from "react-redux";
import GreenTick from "../assets/GreenTick.svg";
import PositiveTagIcon from "../assets/PositiveTagIcon.svg";
import NegativeTagIcon from "../assets/NegativeTagIcon.svg";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';



// ======================================================================================Util functions============================================================================================================================
const ListOfHeadings = ['Marital Status', 'Occupation', 'Diet Pattern', 'Exercise', 'Routine', 'Sleep Pattern', 'Stress Level'];



function CheckTitle({ Bin, keys, index }: { Bin: any, keys: string[], index: number }): JSX.Element {
  const value: any = Bin[keys[index]];
  const keyname = keys[index].replace(/_/g, ' ');
  if (value !== undefined && value !== "" && value !== null) {
    return <div style={{ display: 'inline-flex', alignItems: 'center' }}>
    {value}
    </div>;
  } else if (!ListOfHeadings.includes(keyname)) {
    return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Typography sx={{ color: 'orange', fontSize: 'clamp(15px, 0.9vw, 24px)',}}>
        {keys[index].replace(/_/g, ' ')} 
        </Typography>
        {/* <Typography component="span" sx={{ color: 'orange', fontSize: '0.8vw' }}>
          ND
        </Typography> */}
      </div>
    );
 } else {
    return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Typography variant="h6" sx={{color: 'orange', whiteSpace: 'nowrap',fontSize: 'clamp(15px, 1vw, 24px)',}}>
        {keys[index].replace(/_/g, ' ')} 
        </Typography>
        {/* <Typography component="span" sx={{ color: 'orange', fontSize: '0.8vw' }}>
          ND
        </Typography> */}
      </div>
    );
  }
}



//Helpers for editable state

interface InputFieldProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({ label, value, onChange, required = false }) => (
  <Stack direction="column" spacing={0} sx={{ marginBottom: '8px' }}>
    <Box>
      <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: '14px', color: '#B0B0B0' }}>
        {label} {required && <span style={{ color: 'red' }}>*</span>}
      </h2>
    </Box>
    <Box sx={{ backgroundColor: 'white', padding: '0px', borderRadius: '8px', border: '1px solid darkgray' }}>
      <input
        type="text"
        value={value}
        onChange={onChange}
        style={{ width: '100%', padding: '8px', borderRadius: '6px', border: '0px solid #ccc', fontSize: '14px' }}
      />
    </Box>
  </Stack>
);
interface DropdownFieldProps {
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  options: string[];
  required?: boolean;
  width?: string | number;
  sx?: any;
}

const DropdownField: React.FC<DropdownFieldProps> = ({ label, value, onChange, options, required = false, width = '100%', sx = {} }) => (
  <Box sx={{ width }}>
    <Stack direction="column" spacing={0.1}>
      <Box>
        <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: '14px', color: '#B0B0B0' }}>
          {label} {required && <span style={{ color: 'red' }}>*</span>}
        </h2>
      </Box>
      <Select
        size="small"
        variant="filled"
        value={value}
        onChange={onChange}
        displayEmpty
        renderValue={(selected) => !selected ? <em>--</em> : selected}
        fullWidth
        sx={{
          height: "35px",
          border: '1px solid #CCD4DB',
          backgroundColor: "white",
          '& .MuiSelect-select': {
            padding: '8px 14px',
            borderRadius: '8px',
            backgroundColor: "white",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          ...sx, // Add this to merge additional sx styles
        }}
      >
        <MenuItem value="" disabled><em>--</em></MenuItem>
        {options.map((option: string) => (
          <MenuItem key={option} value={option}>{option}</MenuItem>
        ))}
      </Select>
    </Stack>
  </Box>
);


interface DateFieldProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  width?: string | number;
  sx?: any;
}

const DateField: React.FC<DateFieldProps> = ({ label, value, onChange, required = false, width = '100%', sx = {} }) => (
  <Box sx={{ width }}>
    <Stack direction="column" spacing={0.1}>
      <Box>
        <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: '14px', color: '#B0B0B0' }}>
          {label} {required && <span style={{ color: 'red' }}>*</span>}
        </h2>
      </Box>
      <Box sx={{ backgroundColor: 'white', padding: '0px', borderRadius: '8px', border: '1px solid #CCD4DB' , ...sx}}>
        <input
          type="date"
          value={value}
          onChange={onChange}
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '6px',
            border: 'none',
            fontSize: '14px',
            backgroundColor: 'transparent'
          }}
        />
      </Box>
    </Stack>
  </Box>
);


function TitleSubRow({title, subtitle, subtitle2, showDropdown}: {title: JSX.Element | string, subtitle: JSX.Element | string, subtitle2?: JSX.Element | string, showDropdown: boolean}): JSX.Element {
  if (!subtitle2) { 
    if (typeof title === 'string' && typeof subtitle === 'string') {
      return (
        <Stack direction={subtitle.length < 10 ? "row" : "column"} spacing={ subtitle.length < 10 ? 2 : 0} alignItems={subtitle.length < 10 ? "baseline" : ""}>
            <Typography variant="h6"  sx={{color: 'black', whiteSpace: 'nowrap',fontSize: 'clamp(15px, 1vw, 24px)',}}>
              {title} {showDropdown && <ArrowRightIcon />}
            </Typography>
              <Typography sx={{ color: 'gray', fontSize: 'clamp(15px, 0.8vw, 24px)',}}>
              {subtitle} 
            </Typography>
        </Stack>
      )
  } else {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6" sx={{color: 'black', whiteSpace: 'nowrap',fontSize: 'clamp(15px, 1vw, 24px)',}}>
            {title} {showDropdown && <ArrowRightIcon />}
          </Typography>
            <Typography sx={{ color: 'gray', fontSize: 'clamp(15px, 0.8vw, 24px)',}}>
            {subtitle} 
          </Typography>
      </Stack>
    )
  } 
} else {
  return (
    <Stack direction="column" spacing={0} >
        <Typography variant="h6" sx={{color: 'black', whiteSpace: 'nowrap',fontSize: 'clamp(15px, 1vw, 24px)',}}>
          {title} {showDropdown && <ArrowRightIcon />}
        </Typography>
          <Typography sx={{ color: 'gray', fontSize: 'clamp(15px, 0.8vw, 24px)',}}>
          {subtitle} 
        </Typography>
        <Typography sx={{ color: 'gray', fontSize: 'clamp(15px, 0.8vw, 24px)',}}>
          {subtitle2} 
        </Typography>
    </Stack>
  )
}
}

function SimpleText({text1, text2}: {text1:JSX.Element, text2?:JSX.Element}): JSX.Element {
  if(!text2) {
    return (
      <Typography sx={{ color: 'gray', fontSize: 'clamp(15px, 0.8vw, 24px)'}}>
          {text1} 
        </Typography>
    )
  } else {
    return (
      <Typography sx={{ color: 'gray', fontSize: 'clamp(15px, 0.8vw, 24px)'}}>
          {text1}, {text2} 
        </Typography>
    )
  }
}

function SelectBar({ Bin, title, field, width, updateState }: {Bin: any, title: string, field: string, width?: string, updateState: (field: string, value: string) => void;}): JSX.Element {
  return (
    <Box key={field} sx={{ width: width || "100%"}}>
        <Select
          size="small"
          variant="filled"
          value={Bin[field]}
          onChange={(e) => updateState(field, e.target.value)} //this is updating state of itemstate, will leave it be and focus on designing.
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>{Bin[field] ? Bin[field] : "Not Mentioned"}</em>;
            }
            return selected;
          }}
          fullWidth
          sx={{
            height: "35px",
            border: '1px solid #CCD4DB',
            backgroundColor: "white",
            fontSize: 'clamp(15px, 1vw, 24px)',
            '& .MuiSelect-select': {
              padding: '8px 14px', 
              borderRadius: '8px',
              backgroundColor: "white",
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', 
            },
          }}
        >
          {getFieldOptions(title, field).map((option: any) => (
            <MenuItem key={option} value={option} sx={{fontSize: 'clamp(15px, 1vw, 24px)'}}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
  )
}

function LabelledSelectBar({ Bin, title, field, width, label, updateState }: {Bin: any, title: string, field: string, width?: string, label: string, updateState: (field: string, value: string) => void;}): JSX.Element {
  return (
    <FormControl sx={{ width: width || '100%' }}>
      <InputLabel shrink sx={{fontSize: 'clamp(15px, 1vw, 24px)'}}>{label}</InputLabel>
      <Box sx={{ marginTop: '8px', marginBottom: '8px' }}>
      <SelectBar Bin={Bin} title={title} field={field} updateState={updateState} />
      </Box>
    </FormControl>

  );
}


function MedsDatesLayout({startDate, endDate}: {startDate: JSX.Element, endDate: JSX.Element}): JSX.Element {
  return (
    <Stack direction="row" spacing={1} sx={{ border: '1px solid #CCD4DB', borderRadius: '20px', width: '100%', justifyContent: 'space-between', marginTop: '2px', padding: '7px' }}>
      <Typography component="span" sx={{ color: 'gray', fontSize: 12 }}>
        Start Date: <br/>
        <Typography component="span" sx={{ color: 'black', fontSize: 15  }}>{startDate} </Typography>
      </Typography>
      <Typography component="span" sx={{ color: 'gray', fontSize: 12  }}>
        End Date: <br/>
        <Typography component="span" sx={{ color: 'black', fontSize: 15  }}>{endDate} </Typography>
      </Typography>
    </Stack>
  )
}

function DeleteConfirmation({ handleDeleteClose, handleDeleteConfirm, name }: { handleDeleteClose: () => void, handleDeleteConfirm: () => void, name: string | JSX.Element }): JSX.Element {
  return (
    <div style={{width: "70%", border: '1px solid #CCD4DB', borderRadius: '8px', padding: "10px", backgroundColor: "white"}}>
      <Typography variant="h6" sx={{ color: 'black', textAlign: 'center', padding: '10px' }}>
        Confirm Deletion
      </Typography>

      <Typography sx={{ color: 'black', textAlign: 'center', padding: '10px' }}>
        Are you sure you want to delete "{name}"?
      </Typography>

      <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
        <Button onClick={handleDeleteClose} sx={{ backgroundColor: '#EDF2FC', color: '#008AD8', width: '45%' }}>
          Cancel
        </Button>

        <Button onClick={handleDeleteConfirm} sx={{ backgroundColor: '#D24E4B', color: 'white', width: '45%' }}>
          Delete
      </Button>

      </Stack>
    </div>
  )
}

function SuccessMessage({item, Updated, setShowSuccessMessage}: {item: string, Updated: boolean, setShowSuccessMessage: (value: boolean) => void}): JSX.Element {
  console.log("Updated is: ", Updated);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);
    return () => clearTimeout(timer);
  }
  , [setShowSuccessMessage]);

  return (
    <div style={{width: "70%", border: '2px solid #34BC78', borderRadius: '8px', backgroundColor: "white"}}>
      <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
      <img src={GreenTick} alt="Green Tick" style={{ width: "20px", height: "20px"}} />
      <Typography sx={{ color: 'black', textAlign: 'center', padding: '1px' }}>
      {item} {Updated ? "Updated" : "Added"}  Successfully
      </Typography>
      </Stack>
    </div>
  )

}

function DeleteReason({ handleDeleteClose, handleReasonConfirm, setDelReason }: { handleDeleteClose: () => void, handleReasonConfirm: () => void, setDelReason: (value: string) => void }): JSX.Element {
 
  const [value, setValue] = useState('discontinue');
  setDelReason(value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setDelReason(value);
  }
 
  return (
    <div style={{width: "70%", border: '1px solid #CCD4DB', borderRadius: '8px', padding: "10px", backgroundColor: "white"}}>
      <Typography variant="h6" sx={{ color: 'black', textAlign: 'center', padding: '10px' }}>
        What is the reason for deletion?
      </Typography>

      <FormControl component="fieldset">
        <RadioGroup aria-label="reason" name="reason" value={value} onChange={handleChange}>
          <FormControlLabel value="discontinue" control={<Radio />} label="Discontinue Medication" />
          <FormControlLabel value="error" control={<Radio />} label="Prescription Error" />
        </RadioGroup>
      </FormControl>

      <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
        <Button onClick={handleDeleteClose} sx={{ backgroundColor: '#EDF2FC', color: '#008AD8', width: '45%' }}>
          Cancel
          </Button>

        <Button onClick={handleReasonConfirm} sx={{ backgroundColor: '#D24E4B', color: 'white', width: '45%' }}>
          Delete
      </Button>

      </Stack>
    </div>
  )
}

function DiscontinueReason({ handleDiscontinueClose, handleDiscontinueConfirm, name, setDelReason }: { handleDiscontinueClose: () => void, handleDiscontinueConfirm: () => void, name: string | JSX.Element, setDelReason: (value: string) => void }): JSX.Element {
  const [value, setValue] = useState('');

  const handleChange = (event: SelectChangeEvent<string>) => {
    setValue(event.target.value);
    setDelReason(event.target.value);
  };
  
  return (
    <div style={{width: "70%", border: '1px solid #CCD4DB', borderRadius: '8px', padding: "10px", backgroundColor: "white"}}>
      <Typography variant="h6" sx={{ color: 'black', textAlign: 'center', padding: '10px', marginBottom: "5px" }}>
       Are you sure you want to discontinue "{name}"?
      </Typography>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel shrink>Reason for discontinuing</InputLabel>
        <Select
          size="small"
          variant="filled"
          displayEmpty
          fullWidth
          value={value}
          onChange={handleChange}
          sx={{
            height: "35px",
            backgroundColor: "white",
            marginBottom: "5px" ,
            '& .MuiSelect-select': {
              padding: '8px 14px', 
              borderRadius: '8px',
              backgroundColor: "white",
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', 
            },
          }}
        >
          <MenuItem value="Adverse Drug Effect">Adverse Drug Effect</MenuItem>
          <MenuItem value="Drug Interaction">Drug Interaction</MenuItem>
          <MenuItem value="Duplicate Therapy">Duplicate Therapy</MenuItem>
          <MenuItem value="Ineffective treatment">Ineffective treatment</MenuItem>
          <MenuItem value="No longer needed">No longer needed</MenuItem>
          <MenuItem value="Improve Adherence">Improve Adherence</MenuItem>
          <MenuItem value="Non-formulary">Non-formulary</MenuItem>
          <MenuItem value="Availability Issues">Availability Issues</MenuItem>
          <MenuItem value="Regulatory Recall">Regulatory Recall</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>
      </FormControl>

      <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
        <Button onClick={handleDiscontinueClose} sx={{ backgroundColor: '#EDF2FC', color: '#008AD8', width: '45%' }}>
          Cancel
        </Button>

        <Button onClick={handleDiscontinueConfirm} sx={{ backgroundColor: '#D24E4B', color: 'white', width: '45%' }}>
          Delete
        </Button>
      </Stack>
    </div>
  );
}





// ===============================================================================SOCIAL HISTORY BIN============================================================================================================================

export function SocialHxBinInActive({ Bin }: SocialHxInActiveProps) {
  return (
    <Stack spacing={0}>
      {Object.entries(Bin).map(([mainCategory, subCategories]) => (
        <Box key={mainCategory}>
          <Typography variant="h6" component="div" sx={{ color: 'black', mt: mainCategory === "Personal" ? 0 : 1, mb: 1 }}> 
            {mainCategory.replace(/_/g, ' ')}
          </Typography>
          {Object.entries(subCategories).map(([subCategory, value]) => (
            <Box key={subCategory}>
              <Typography component="span" sx={{ color: 'gray' }}>
                {subCategory.replace(/_/g, ' ')}
              </Typography>
              <Typography
                component="span"
                sx={{ color: value ? 'gray' : 'gray' }}
              >
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}
    </Stack>
  );
}


export function SocialHxBinActive({ Bin }: any) {

  const keys = Object.keys(Bin);
  return (
      <Stack spacing={0}>
      {/* Marital Status */}
     <TitleSubRow showDropdown={false} title={CheckTitle({Bin, keys, index: 0})} subtitle={CheckTitle({Bin, keys, index: 1})} />
        {/* Occupation */}
       <Stack direction="row" spacing={13}>
         <TitleSubRow showDropdown={false} title={CheckTitle({Bin, keys, index: 2})} subtitle={CheckTitle({Bin, keys, index: 3})} />
          <SimpleText text1={CheckTitle({Bin, keys, index: 4})} />
       </Stack>
      {/* Diet */}
       <TitleSubRow showDropdown={false} title={CheckTitle({Bin, keys, index: 5})} subtitle={CheckTitle({Bin, keys, index: 6})} />
       {/* Exercise */}
       <Stack direction="row" spacing={12}>
       <TitleSubRow showDropdown={false} title={<>Exercise</>} subtitle={CheckTitle({Bin, keys, index: 7})} />
       <SimpleText text1={CheckTitle({Bin, keys, index: 9})} text2={CheckTitle({Bin, keys, index: 8})} />
       </Stack>
       {/* routine */}
       <TitleSubRow showDropdown={false} title={CheckTitle({Bin, keys, index: 10})} subtitle={<></>} />
       {/* Sleep */}
       <Stack direction="row" spacing={12}>
      <TitleSubRow showDropdown={false} title={CheckTitle({Bin, keys, index: 11})} subtitle={CheckTitle({Bin, keys, index: 12})} subtitle2={CheckTitle({Bin, keys, index: 13})} />
      <SimpleText text1={CheckTitle({Bin, keys, index: 14})} />
      </Stack>
      {/* Stress */}
      <TitleSubRow showDropdown={false} title={ <> {CheckTitle({Bin, keys, index: 15})}  Stress Level</>} subtitle={<>stressors: {CheckTitle({Bin, keys, index: 16})} </>} subtitle2={CheckTitle({Bin, keys, index: 17})} />
    </Stack>
  )
}

interface BinField {
  original: string;
  edited: string;
}

interface Bin {
  [key: string]: BinField;
}

export function SocialHxBinEditable( { Bin, title, updateState }: {Bin: any, title: string,  updateState: (key: string, value: string) => void}) {
  const [MaritalStatusView, setMaritalStatus] = useState(true);
  const [OccupationView, setOccupation] = useState(true);
  const [DietView, setDiet] = useState(true);
  const [ExerciseView, setExercise] = useState(true);
  const [RoutineView, setRoutine] = useState(true);
  const [SleepView, setSleep] = useState(true);
  const [StressView, setStress] = useState(true);
  const [showMaritalDropdown, setMaritalShowDropdown] = useState(false);
  const [showOccupationDropdown, setOccupationShowDropdown] = useState(false);
  const [showDietDropdown, setDietShowDropdown] = useState(false);
  const [showExerciseDropdown, setExerciseShowDropdown] = useState(false);
  const [showRoutineDropdown, setRoutineShowDropdown] = useState(false);
  const [showSleepDropdown, setSleepShowDropdown] = useState(false);
  const [showStressDropdown, setStressShowDropdown] = useState(false);


  const keys = Object.keys(Bin);

  const toggleMaritalStatus = () => {
    setMaritalStatus(!MaritalStatusView);
  };

  const toggleOccupation = () => {
    setOccupation(!OccupationView);
  };

  const toggleDiet = () => {
    setDiet(!DietView);
  };

  const toggleExercise = () => {
    setExercise(!ExerciseView);
  };

  const toggleRoutine = () => {
    setRoutine(!RoutineView);
  };

  const toggleSleep = () => {
    setSleep(!SleepView);
  };

  const toggleStress = () => {
    setStress(!StressView);
  };

  const handleMaritalMouseEnter = () => {
    setMaritalShowDropdown(true);
  };

  const handleOccupationMouseEnter = () => {
    setOccupationShowDropdown(true);
  }
  
  const handleMaritalMouseLeave = () => {
    setMaritalShowDropdown(false);
  };

  const handleOccupationMouseLeave = () => {
    setOccupationShowDropdown(false);
  };

  const handleDietMouseEnter = () => {
    setDietShowDropdown(true);
  };
  
  const handleDietMouseLeave = () => {
    setDietShowDropdown(false);
  };
  
  const handleExerciseMouseEnter = () => {
    setExerciseShowDropdown(true);
  };
  
  const handleExerciseMouseLeave = () => {
    setExerciseShowDropdown(false);
  };
  
  const handleRoutineMouseEnter = () => {
    setRoutineShowDropdown(true);
  };
  
  const handleRoutineMouseLeave = () => {
    setRoutineShowDropdown(false);
  };
  
  const handleSleepMouseEnter = () => {
    setSleepShowDropdown(true);
  };
  
  const handleSleepMouseLeave = () => {
    setSleepShowDropdown(false);
  };
  
  const handleStressMouseEnter = () => {
    setStressShowDropdown(true);
  };
  
  const handleStressMouseLeave = () => {
    setStressShowDropdown(false);
  };

  const getCurrentValue = (field: BinField) => field.edited || field.original;
  
  const handleUpdate = (key: string, value: string) => {
    updateState(`${title}#${key}`, value);
  };
  


  return (
    <Stack spacing={0} sx={{marginBottom: "20px"}}>
      {/* Marital Status */}
      <div onMouseEnter={handleMaritalMouseEnter} onMouseLeave={handleMaritalMouseLeave}>
        <div onClick={toggleMaritalStatus}>
          {MaritalStatusView ? (
            <TitleSubRow 
              title={getCurrentValue(Bin["Marital Status"])}
              subtitle={getCurrentValue(Bin["Identity"])}
              showDropdown={showMaritalDropdown}
            />
          ) : (
            <>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Marital Status</h2></Box>
                <ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
              </Stack>
              <Box sx={{ backgroundColor: '#F8F9FA', padding: '14px', borderRadius: '8px', border: '1px solid darkgray' }}>
                <Stack direction="row" spacing={1}>
                  <LabelledSelectBar 
                    Bin={Bin["Marital Status"]}
                    title={title}
                    field="Marital Status"
                    label="Marital Status"
                    width="60%"
                    updateState={handleUpdate}
                  />
                  <LabelledSelectBar 
                    Bin={Bin["Identity"]}
                    title={title}
                    field="Identity"
                    label="Identity"
                    width="40%"
                    updateState={handleUpdate}
                  />
                </Stack>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    borderRadius: '8px',
                    marginTop: '16px',
                    width: '100px', 
                    height: '35px',
                    marginLeft: 'auto', 
                    display: 'block' 
                  }}
                >
                  Save
                </Button>
              </Box>
            </>
          )}
        </div>
      </div>

      {/* Occupation and education */}
      <div onMouseEnter={handleOccupationMouseEnter} onMouseLeave={handleOccupationMouseLeave} >
      <div onClick={toggleOccupation}>
        {OccupationView ? (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
          <TitleSubRow showDropdown={showOccupationDropdown} title={CheckTitle({Bin, keys, index: 2})} subtitle={CheckTitle({Bin, keys, index: 3})} />
          <SimpleText text1={CheckTitle({Bin, keys, index: 4})} />
          </Stack>
        ) : (
          <>
          <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Occupation</h2> </Box>
          < ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
          </Stack>
          <Box sx={{ backgroundColor: '#F8F9FA', padding: '18px', borderRadius: '8px',  border: '1px solid darkgray' }}>
          <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[2]} label="Occupation" width="100%" updateState={updateState} />
          <Stack direction="row" spacing={1} sx={{ marginTop: '8px' }}>
          <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[3]} label="Occupation Type" width="60%" updateState={updateState} />
          <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[4]} label="Education" width="40%" updateState={updateState} />
          </Stack>
          <Button
              variant="contained"
              color="success"
              sx={{
                borderRadius: '8px',
                marginTop: '16px',
                width: '100px', 
                height: '35px',
                marginLeft: 'auto', 
                display: 'block' 
              }}
            >
              Save
            </Button>
          </Box>
          </>
        )}
      </div>
      </div>



      {/* Diet */}
      <div onMouseEnter={handleDietMouseEnter} onMouseLeave={handleDietMouseLeave} style={{ marginTop: "15px" }}>
      <div onClick={toggleDiet}>
        {DietView ? (
          <TitleSubRow showDropdown={showDietDropdown} title={CheckTitle({Bin, keys, index: 5})} subtitle={CheckTitle({Bin, keys, index: 6})} />
        ) : (
          <>
          <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Diet</h2> </Box>
          < ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
          </Stack>
          <Box sx={{ backgroundColor: '#F8F9FA', padding: '18px', borderRadius: '8px',  border: '1px solid darkgray' }}>
          <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[5]} label="Diet pattern" width="100%" updateState={updateState} />
          <div style={{ marginTop: '8px' }}>
          <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[6]} label="Diet Specifics" width="100%" updateState={updateState} />
          </div>
          <Button
              variant="contained"
              color="success"
              sx={{
                borderRadius: '8px',
                marginTop: '16px',
                width: '100px', 
                height: '35px',
                marginLeft: 'auto', 
                display: 'block' 
              }}
            >
              Save
            </Button>
          </Box>
          </>
        )}
      </div>
      </div>


      {/* Exercise */}
      <div onMouseEnter={handleExerciseMouseEnter} onMouseLeave={handleExerciseMouseLeave} >
      <div onClick={toggleExercise}>
        {ExerciseView ? (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="column" spacing={0}>
              <TitleSubRow showDropdown={showExerciseDropdown} title={<>Exercise</>} subtitle={<></>} />
              <SimpleText text1={CheckTitle({Bin, keys, index: 7})} />
            </Stack>
          <SimpleText text1={CheckTitle({Bin, keys, index: 9})} text2={CheckTitle({Bin, keys, index: 8})} />
          </Stack>
        ) : (
          <>
          <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Exercise</h2> </Box>
          < ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
          </Stack>
        <Box sx={{ backgroundColor: '#F8F9FA', padding: '18px', borderRadius: '8px',  border: '1px solid darkgray' }}>
        <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[7]} label="Type of Exercise" width="100%" updateState={updateState} />
        <Stack direction="row" spacing={1} sx={{ marginTop: '8px' }}>
        <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[8]} label="No. of days per week" width="60%" updateState={updateState} />
        <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[9]} label="Duration" width="40%" updateState={updateState} />
        </Stack>
        <Button
              variant="contained"
              color="success"
              sx={{
                borderRadius: '8px',
                marginTop: '16px',
                width: '100px', 
                height: '35px',
                marginLeft: 'auto', 
                display: 'block' 
              }}
            >
              Save
            </Button>
        </Box>
        </>
        )}
      </div>
      </div>





      {/* Routine */}
      <div onMouseEnter={handleRoutineMouseEnter} onMouseLeave={handleRoutineMouseLeave} >
      <div onClick={toggleRoutine}>
        {RoutineView ? (
            <TitleSubRow showDropdown={showRoutineDropdown} title={CheckTitle({Bin, keys, index: 10})} subtitle={<></>} />
        ) : (
          <>
          <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Routine</h2> </Box>
          < ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
          </Stack>
          <Box sx={{ backgroundColor: '#F8F9FA', padding: '18px', borderRadius: '8px',  border: '1px solid darkgray' }}>
          <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[10]} label="How often does your daily schedule change?" width="100%" updateState={updateState} />
          <Button
              variant="contained"
              color="success"
              sx={{
                borderRadius: '8px',
                marginTop: '16px',
                width: '100px', 
                height: '35px',
                marginLeft: 'auto', 
                display: 'block' 
              }}
            >
              Save
            </Button>
          </Box>
          </>
        )}
      </div>
      </div>


      {/* Sleep */}
      <div onMouseEnter={handleSleepMouseEnter} onMouseLeave={handleSleepMouseLeave} >
      <div onClick={toggleSleep}>
        {SleepView ? (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
        <TitleSubRow showDropdown={showSleepDropdown} title={CheckTitle({Bin, keys, index: 11})} subtitle={CheckTitle({Bin, keys, index: 12})} subtitle2={CheckTitle({Bin, keys, index: 13})} />
      <SimpleText text1={CheckTitle({Bin, keys, index: 14})} />
          </Stack>
        ) : (
          <>
          <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Sleep</h2> </Box>
          < ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
          </Stack>
      <Box sx={{ backgroundColor: '#F8F9FA', padding: '18px', borderRadius: '8px',  border: '1px solid darkgray' }}>
      <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[11]} label="Sleep Pattern" width="100%" updateState={updateState} />
      <div style={{ marginTop: '8px' }}>
      <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[12]} label="Do you experience following types of insomnia?" width="100%" updateState={updateState} />
      </div>
      <div style={{ marginTop: '8px' }}>
      <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[13]} label="Rate your Sleep Quality" width="100%" updateState={updateState} />
      </div>
      <div style={{ marginTop: '8px' }}>
      <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[14]} label="Hours of Sleep" width="100%" updateState={updateState} />
      </div>
      <Button
              variant="contained"
              color="success"
              sx={{
                borderRadius: '8px',
                marginTop: '16px',
                width: '100px', 
                height: '35px',
                marginLeft: 'auto', 
                display: 'block' 
              }}
            >
              Save
            </Button>
      </Box>
      </>
        )}
      </div>
      </div>


      {/* Stress */}
      <div onMouseEnter={handleStressMouseEnter} onMouseLeave={handleStressMouseLeave} >
      <div onClick={toggleStress}>
          {StressView ? (
            <TitleSubRow showDropdown={showStressDropdown} title={CheckTitle({Bin, keys, index: 15})} subtitle={<>stressors: {CheckTitle({Bin, keys, index: 16})} </>} subtitle2={CheckTitle({Bin, keys, index: 17})} />
          ) : (
            <>
            <Stack direction="row" alignItems="center" spacing={0.5}>
            <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Stress</h2> </Box>
            < ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
            </Stack>
            <Box sx={{ backgroundColor: '#F8F9FA', padding: '18px', borderRadius: '8px',  border: '1px solid darkgray' }}>
            <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[15]} label="Current Stress Level" width="100%" updateState={updateState} />
            <div style={{ marginTop: '8px' }}>
            <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[16]} label="Stress Related Symptoms" width="100%" updateState={updateState} />
            </div>
            <div style={{ marginTop: '8px' }}>
            <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[17]} label="What are your daily stress-management practices" width="100%" updateState={updateState} />
            </div>
            <Button
              variant="contained"
              color="success"
              sx={{
                borderRadius: '8px',
                marginTop: '16px',
                width: '100px', 
                height: '35px',
                marginLeft: 'auto', 
                display: 'block' 
              }}
            >
              Save
            </Button>
            </Box>
            </>
          )}
      </div>
      </div>


  </Stack>
    );
  }


// export function SocialHxBinEditable( { Bin, title, updateState }: {Bin: any, title: string,  updateState: (key: string, value: string) => void;}) {
//   const [MaritalStatusView, setMaritalStatus] = useState(true);
//   const [OccupationView, setOccupation] = useState(true);
//   const [DietView, setDiet] = useState(true);
//   const [ExerciseView, setExercise] = useState(true);
//   const [RoutineView, setRoutine] = useState(true);
//   const [SleepView, setSleep] = useState(true);
//   const [StressView, setStress] = useState(true);
//   const [showMaritalDropdown, setMaritalShowDropdown] = useState(false);
//   const [showOccupationDropdown, setOccupationShowDropdown] = useState(false);
//   const [showDietDropdown, setDietShowDropdown] = useState(false);
//   const [showExerciseDropdown, setExerciseShowDropdown] = useState(false);
//   const [showRoutineDropdown, setRoutineShowDropdown] = useState(false);
//   const [showSleepDropdown, setSleepShowDropdown] = useState(false);
//   const [showStressDropdown, setStressShowDropdown] = useState(false);


//   const keys = Object.keys(Bin);

//   const toggleMaritalStatus = () => {
//     setMaritalStatus(!MaritalStatusView);
//   };

//   const toggleOccupation = () => {
//     setOccupation(!OccupationView);
//   };

//   const toggleDiet = () => {
//     setDiet(!DietView);
//   };

//   const toggleExercise = () => {
//     setExercise(!ExerciseView);
//   };

//   const toggleRoutine = () => {
//     setRoutine(!RoutineView);
//   };

//   const toggleSleep = () => {
//     setSleep(!SleepView);
//   };

//   const toggleStress = () => {
//     setStress(!StressView);
//   };

//   const handleMaritalMouseEnter = () => {
//     setMaritalShowDropdown(true);
//   };

//   const handleOccupationMouseEnter = () => {
//     setOccupationShowDropdown(true);
//   }
  
//   const handleMaritalMouseLeave = () => {
//     setMaritalShowDropdown(false);
//   };

//   const handleOccupationMouseLeave = () => {
//     setOccupationShowDropdown(false);
//   };

//   const handleDietMouseEnter = () => {
//     setDietShowDropdown(true);
//   };
  
//   const handleDietMouseLeave = () => {
//     setDietShowDropdown(false);
//   };
  
//   const handleExerciseMouseEnter = () => {
//     setExerciseShowDropdown(true);
//   };
  
//   const handleExerciseMouseLeave = () => {
//     setExerciseShowDropdown(false);
//   };
  
//   const handleRoutineMouseEnter = () => {
//     setRoutineShowDropdown(true);
//   };
  
//   const handleRoutineMouseLeave = () => {
//     setRoutineShowDropdown(false);
//   };
  
//   const handleSleepMouseEnter = () => {
//     setSleepShowDropdown(true);
//   };
  
//   const handleSleepMouseLeave = () => {
//     setSleepShowDropdown(false);
//   };
  
//   const handleStressMouseEnter = () => {
//     setStressShowDropdown(true);
//   };
  
//   const handleStressMouseLeave = () => {
//     setStressShowDropdown(false);
//   };
  


//   return (
//     <Stack spacing={0} sx={{marginBottom: "20px"}}>
//       {/* Marital Status in-place: */}
//       <div onMouseEnter={handleMaritalMouseEnter} onMouseLeave={handleMaritalMouseLeave}>
//         <div onClick={toggleMaritalStatus} >
//           {MaritalStatusView ? (
//           <TitleSubRow title={CheckTitle({Bin, keys, index: 0})} subtitle={CheckTitle({Bin, keys, index: 1})} showDropdown={showMaritalDropdown}/>
//           ) : (
//             <>
//             <Stack direction="row" alignItems="center" spacing={0.5}>
//             <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Marital Status</h2> </Box>
//             < ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
//             </Stack>
//             <Box sx={{ backgroundColor: '#F8F9FA', padding: '14px', borderRadius: '8px',  border: '1px solid darkgray' }}>
//             <Stack direction="row" spacing={1}>
//             <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[0]} label="Marital Status" width="60%" updateState={updateState} />
//             <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[1]} label="Identity" width="40%" updateState={updateState} />
//             </Stack>
//             <Button
//               variant="contained"
//               color="success"
//               sx={{
//                 borderRadius: '8px',
//                 marginTop: '16px',
//                 width: '100px', 
//                 height: '35px',
//                 marginLeft: 'auto', 
//                 display: 'block' 
//               }}
//             >
//               Save
//             </Button>
//             </Box>
//             </>
//           )}
//         </div>
//        </div>



//       {/* Occupation and education */}
//       <div onMouseEnter={handleOccupationMouseEnter} onMouseLeave={handleOccupationMouseLeave} >
//       <div onClick={toggleOccupation}>
//         {OccupationView ? (
//           <Stack direction="row" justifyContent="space-between" alignItems="center">
//           <TitleSubRow showDropdown={showOccupationDropdown} title={CheckTitle({Bin, keys, index: 2})} subtitle={CheckTitle({Bin, keys, index: 3})} />
//           <SimpleText text1={CheckTitle({Bin, keys, index: 4})} />
//           </Stack>
//         ) : (
//           <>
//           <Stack direction="row" alignItems="center" spacing={0.5}>
//           <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Occupation</h2> </Box>
//           < ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
//           </Stack>
//           <Box sx={{ backgroundColor: '#F8F9FA', padding: '18px', borderRadius: '8px',  border: '1px solid darkgray' }}>
//           <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[2]} label="Occupation" width="100%" updateState={updateState} />
//           <Stack direction="row" spacing={1} sx={{ marginTop: '8px' }}>
//           <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[3]} label="Occupation Type" width="60%" updateState={updateState} />
//           <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[4]} label="Education" width="40%" updateState={updateState} />
//           </Stack>
//           <Button
//               variant="contained"
//               color="success"
//               sx={{
//                 borderRadius: '8px',
//                 marginTop: '16px',
//                 width: '100px', 
//                 height: '35px',
//                 marginLeft: 'auto', 
//                 display: 'block' 
//               }}
//             >
//               Save
//             </Button>
//           </Box>
//           </>
//         )}
//       </div>
//       </div>



//       {/* Diet */}
//       <div onMouseEnter={handleDietMouseEnter} onMouseLeave={handleDietMouseLeave} style={{ marginTop: "15px" }}>
//       <div onClick={toggleDiet}>
//         {DietView ? (
//           <TitleSubRow showDropdown={showDietDropdown} title={CheckTitle({Bin, keys, index: 5})} subtitle={CheckTitle({Bin, keys, index: 6})} />
//         ) : (
//           <>
//           <Stack direction="row" alignItems="center" spacing={0.5}>
//           <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Diet</h2> </Box>
//           < ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
//           </Stack>
//           <Box sx={{ backgroundColor: '#F8F9FA', padding: '18px', borderRadius: '8px',  border: '1px solid darkgray' }}>
//           <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[5]} label="Diet pattern" width="100%" updateState={updateState} />
//           <div style={{ marginTop: '8px' }}>
//           <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[6]} label="Diet Specifics" width="100%" updateState={updateState} />
//           </div>
//           <Button
//               variant="contained"
//               color="success"
//               sx={{
//                 borderRadius: '8px',
//                 marginTop: '16px',
//                 width: '100px', 
//                 height: '35px',
//                 marginLeft: 'auto', 
//                 display: 'block' 
//               }}
//             >
//               Save
//             </Button>
//           </Box>
//           </>
//         )}
//       </div>
//       </div>


//       {/* Exercise */}
//       <div onMouseEnter={handleExerciseMouseEnter} onMouseLeave={handleExerciseMouseLeave} >
//       <div onClick={toggleExercise}>
//         {ExerciseView ? (
//           <Stack direction="row" justifyContent="space-between" alignItems="center">
//             <Stack direction="column" spacing={0}>
//               <TitleSubRow showDropdown={showExerciseDropdown} title={<>Exercise</>} subtitle={<></>} />
//               <SimpleText text1={CheckTitle({Bin, keys, index: 7})} />
//             </Stack>
//           <SimpleText text1={CheckTitle({Bin, keys, index: 9})} text2={CheckTitle({Bin, keys, index: 8})} />
//           </Stack>
//         ) : (
//           <>
//           <Stack direction="row" alignItems="center" spacing={0.5}>
//           <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Exercise</h2> </Box>
//           < ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
//           </Stack>
//         <Box sx={{ backgroundColor: '#F8F9FA', padding: '18px', borderRadius: '8px',  border: '1px solid darkgray' }}>
//         <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[7]} label="Type of Exercise" width="100%" updateState={updateState} />
//         <Stack direction="row" spacing={1} sx={{ marginTop: '8px' }}>
//         <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[8]} label="No. of days per week" width="60%" updateState={updateState} />
//         <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[9]} label="Duration" width="40%" updateState={updateState} />
//         </Stack>
//         <Button
//               variant="contained"
//               color="success"
//               sx={{
//                 borderRadius: '8px',
//                 marginTop: '16px',
//                 width: '100px', 
//                 height: '35px',
//                 marginLeft: 'auto', 
//                 display: 'block' 
//               }}
//             >
//               Save
//             </Button>
//         </Box>
//         </>
//         )}
//       </div>
//       </div>





//       {/* Routine */}
//       <div onMouseEnter={handleRoutineMouseEnter} onMouseLeave={handleRoutineMouseLeave} >
//       <div onClick={toggleRoutine}>
//         {RoutineView ? (
//             <TitleSubRow showDropdown={showRoutineDropdown} title={CheckTitle({Bin, keys, index: 10})} subtitle={<></>} />
//         ) : (
//           <>
//           <Stack direction="row" alignItems="center" spacing={0.5}>
//           <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Routine</h2> </Box>
//           < ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
//           </Stack>
//           <Box sx={{ backgroundColor: '#F8F9FA', padding: '18px', borderRadius: '8px',  border: '1px solid darkgray' }}>
//           <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[10]} label="How often does your daily schedule change?" width="100%" updateState={updateState} />
//           <Button
//               variant="contained"
//               color="success"
//               sx={{
//                 borderRadius: '8px',
//                 marginTop: '16px',
//                 width: '100px', 
//                 height: '35px',
//                 marginLeft: 'auto', 
//                 display: 'block' 
//               }}
//             >
//               Save
//             </Button>
//           </Box>
//           </>
//         )}
//       </div>
//       </div>


//       {/* Sleep */}
//       <div onMouseEnter={handleSleepMouseEnter} onMouseLeave={handleSleepMouseLeave} >
//       <div onClick={toggleSleep}>
//         {SleepView ? (
//           <Stack direction="row" justifyContent="space-between" alignItems="center">
//         <TitleSubRow showDropdown={showSleepDropdown} title={CheckTitle({Bin, keys, index: 11})} subtitle={CheckTitle({Bin, keys, index: 12})} subtitle2={CheckTitle({Bin, keys, index: 13})} />
//       <SimpleText text1={CheckTitle({Bin, keys, index: 14})} />
//           </Stack>
//         ) : (
//           <>
//           <Stack direction="row" alignItems="center" spacing={0.5}>
//           <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Sleep</h2> </Box>
//           < ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
//           </Stack>
//       <Box sx={{ backgroundColor: '#F8F9FA', padding: '18px', borderRadius: '8px',  border: '1px solid darkgray' }}>
//       <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[11]} label="Sleep Pattern" width="100%" updateState={updateState} />
//       <div style={{ marginTop: '8px' }}>
//       <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[12]} label="Do you experience following types of insomnia?" width="100%" updateState={updateState} />
//       </div>
//       <div style={{ marginTop: '8px' }}>
//       <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[13]} label="Rate your Sleep Quality" width="100%" updateState={updateState} />
//       </div>
//       <div style={{ marginTop: '8px' }}>
//       <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[14]} label="Hours of Sleep" width="100%" updateState={updateState} />
//       </div>
//       <Button
//               variant="contained"
//               color="success"
//               sx={{
//                 borderRadius: '8px',
//                 marginTop: '16px',
//                 width: '100px', 
//                 height: '35px',
//                 marginLeft: 'auto', 
//                 display: 'block' 
//               }}
//             >
//               Save
//             </Button>
//       </Box>
//       </>
//         )}
//       </div>
//       </div>


//       {/* Stress */}
//       <div onMouseEnter={handleStressMouseEnter} onMouseLeave={handleStressMouseLeave} >
//       <div onClick={toggleStress}>
//           {StressView ? (
//             <TitleSubRow showDropdown={showStressDropdown} title={CheckTitle({Bin, keys, index: 15})} subtitle={<>stressors: {CheckTitle({Bin, keys, index: 16})} </>} subtitle2={CheckTitle({Bin, keys, index: 17})} />
//           ) : (
//             <>
//             <Stack direction="row" alignItems="center" spacing={0.5}>
//             <Box><h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> Stress</h2> </Box>
//             < ArrowDropDownIcon sx={{ fontSize: 30 }}/> 
//             </Stack>
//             <Box sx={{ backgroundColor: '#F8F9FA', padding: '18px', borderRadius: '8px',  border: '1px solid darkgray' }}>
//             <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[15]} label="Current Stress Level" width="100%" updateState={updateState} />
//             <div style={{ marginTop: '8px' }}>
//             <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[16]} label="Stress Related Symptoms" width="100%" updateState={updateState} />
//             </div>
//             <div style={{ marginTop: '8px' }}>
//             <LabelledSelectBar Bin={Bin} title={title} field={Object.keys(Bin)[17]} label="What are your daily stress-management practices" width="100%" updateState={updateState} />
//             </div>
//             <Button
//               variant="contained"
//               color="success"
//               sx={{
//                 borderRadius: '8px',
//                 marginTop: '16px',
//                 width: '100px', 
//                 height: '35px',
//                 marginLeft: 'auto', 
//                 display: 'block' 
//               }}
//             >
//               Save
//             </Button>
//             </Box>
//             </>
//           )}
//       </div>
//       </div>


//   </Stack>
//     );
//   }



// ======================================================================================MEDICINE BIN============================================================================================================================

export function MedsBinInActive({ Bin }: MedsInActiveProps) {
  return (
    <>
  <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
    <Stack>
    <Stack key="Bin" direction="row" alignItems="center" spacing={1}>
    <Typography component="div" variant="h6"  sx={{ color: 'Black', mr: 1 }}>
      Trade Name
    </Typography>
    <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
      Dosage /Unit
    </Typography>
  </Stack>
  <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
    Salt Name <br/>
    Prescribed By: 
  </Typography>
  </Stack>
    <Stack>
  <Stack direction="row" alignItems="flex-start" spacing={3} sx={{mr: 1}}>
    <EventRepeatIcon  sx={{ color: 'gray', fontSize: 21 }} />
    <EditIcon sx={{ color: 'gray', fontSize: 21 }} />
    <DeleteOutlineIcon sx={{ color: 'gray', fontSize: 21 }} />
  </Stack>
  <Typography component="span" sx={{ color: 'gray', mr: 1, margin: 0, textAlign: 'right', display: 'block' }}>
    Route <br />
    Frequency
  </Typography>
  </Stack>
  </Stack>
  </>
  )
}




function MedsTradeLayout({ Bin, handleDelete, setShowDeleteConfirmation, setShowDeleteReason, setShowDiscontinueConfirmation, setDelName, onStateChange, setItemsDisplay, setShowSuccessMessage }: { Bin: MedsInActiveProps, handleDelete: (TradeName: string) => void, setShowDeleteConfirmation: (value: boolean) => void, setShowDeleteReason: (value: boolean) => void, setShowDiscontinueConfirmation: (value: boolean) => void, setDelName: (value: string) => void,   onStateChange: (key: string, value: string, updateState?: any) => void, setItemsDisplay: any, setShowSuccessMessage: any }): JSX.Element {
  let keys = Object.keys(Bin);
  let values = Object.values(Bin);
  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );  
  const [DateClicked, setDateClicked] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inEditing, setInEditing] = useState<boolean>(false);
  
  let incomplete = false;

  for (let i = 0; i < values.length; i++) {
    if (values[i] === "" || values[i] === undefined) {
      incomplete = true;
      break;
    }
  }

  const toggleDateClicked = () => setDateClicked(!DateClicked);
  const toggleEditModeOn = () => setInEditing(true);
  const toggleEditModeOff = () => setInEditing(false);
  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  
  
  //global Close/ cancel button
  const handleDeleteClose = () => {
    setAnchorEl(null);
  }

  const handleMenuItemClick = (option: string) => {
    console.log(option);
    setDelName(values[0])
    if (option === "Delete") {
      console.log("Delete clicked");
      setShowDeleteConfirmation(false);
      setShowDeleteReason(true);
    } else if (option === "Discontinue") {
      setShowDiscontinueConfirmation(true);
    }else {
      handleDeleteClose();
    }
  };

  return (
    <>

    {inEditing ? (

      <MedsTradeEditableLayout  Bin={Bin} updateState={setItemsDisplay} handleClose={toggleEditModeOff} onStateChange={onStateChange}  setShowSuccessMessage={setShowSuccessMessage} />

    ) : (



      <div style={{ backgroundColor: incomplete ? '#F8D7DA' : 'white', padding: '5px', borderRadius: '8px', margin: 0 }}>
    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
      <Stack>
      <Stack key="Bin" direction="row" alignItems="center" spacing={1}>
      <Typography component="div" variant="h6"  sx={{ color: 'Black', mr: 1 }}>
        {CheckTitle({Bin, keys, index: 0})}
      </Typography>
      <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
        {CheckTitle({Bin, keys, index: 2})} / {CheckTitle({Bin, keys, index: 3})}
      </Typography>
    </Stack>
    <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
      {CheckTitle({Bin, keys, index: 1})} <br/>
      Prescribed by: {currentPhysician}
    </Typography>
    </Stack>
      <Stack>
    <Stack direction="row" alignItems="flex-end" spacing={1} sx={{ ml: 'auto' }}>

      <IconButton
        onClick={toggleDateClicked}
        sx={{ color: 'gray' }} >
      <EventRepeatIcon  sx={{ fontSize: 21 }} />
      </IconButton>


      <IconButton
        sx={{ color: 'gray' }}
        onClick={toggleEditModeOn}
      >
        <EditIcon sx={{ fontSize: 21 }} />
      </IconButton>



      <IconButton
        onClick={handleDeleteClick}
        sx={{ color: 'gray' }}
      >
        <DeleteOutlineIcon sx={{ fontSize: 21 }} />
      </IconButton>
    </Stack>
      {
        DateClicked ? (
          MedsDatesLayout({startDate: CheckTitle({Bin, keys, index: 6}), endDate: CheckTitle({Bin, keys, index: 7})})
        ) : (
          <Typography component="span" sx={{ color: 'gray', mr: 1, margin: 0, textAlign: 'right', display: 'block' }}>
          {CheckTitle({Bin, keys, index: 4})} <br />
          {CheckTitle({Bin, keys, index: 5})}
          </Typography>
        )
      }
    </Stack>
    </Stack>

    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleDeleteClose}
    >
      <MenuItem onClick={() => handleMenuItemClick('Discontinue')}>Discontinue</MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('Delete')}>Delete</MenuItem>
    </Menu>

    </div>
  )}

    </>
  )
}

interface BinActiveProps {
  Bin: any; // Replace 'any' with the appropriate type for 'Bin' if known
  onStateChange: (key: string, value: string, updateState?: any) => void;
  DeleteItemFromBin: (key: string, reason: string, updateState: any) => void;
}

interface MedsBinActiveState {
  Trade_Name: string;
  Salt: string;
  Number: string;
  Unit: string;
  Route: string;
  Frequency: string;
  Start_date: string;
  End_date: string;
}

//remove these temp fixes if fixing data from the backend doesnt work.
export function MedsBinActive({ Bin, onStateChange, DeleteItemFromBin }: BinActiveProps) {
  // console.log(`[TESTING MEDSBIN] Bin in MedsBinActive: `, JSON.stringify(Bin));
  let BinArray = Array.isArray(Bin) ? Bin : [Bin];
  // console.log(`[TESTING MEDSBIN] BinArray in MedsBinActive: ${JSON.stringify(BinArray)}`);

  const [expanded, setExpanded] = useState(false);
  const [AddNew, setAddNew] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  let topItems = expanded ? BinArray : BinArray.slice(0, 3);
  // let topItems = BinArray;
  // console.log(`[TESTING MEDSBIN] topItems in MedsBinActive: ${JSON.stringify(topItems)}`);
  const [ItemsDisplay, setItemsDisplay] = useState(topItems);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteReason, setShowDeleteReason] = useState(false);
  const [showDiscotinueConfirmation, setShowDiscontinueConfirmation] = useState(false);
  const [delName, setDelName] = useState("");
  const [delReason, setDelReason] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  let UpdatedBin = true

  useEffect(() => {
    setItemsDisplay(topItems);
  }, [expanded]);

  //DeleteFunction for medicine:
  const handleDeleteMedicine = (TradeName: string): any => {
    TradeName = TradeName.toString();
    const key: string = 'meds#'.concat(TradeName);
    DeleteItemFromBin(key, delReason, setItemsDisplay);
    BinArray = BinArray.filter((item: MedsBinActiveState) => item.Trade_Name !== TradeName);
    topItems = expanded ? BinArray : BinArray.slice(0, 3);
    setItemsDisplay(topItems);
    console.log("Bin after deletion: ", JSON.stringify(BinArray, null, 2));
  };

  const handleCancel = () => {
    setShowDeleteConfirmation(false);
    setShowDeleteReason(false);
    setShowDiscontinueConfirmation(false);
    setDelName("");
  }

  const handleDeleteConfirm = () => {
    console.log("Delete confirmed, now remove it from list");
    handleDeleteMedicine(delName);
    handleCancel();
  };

  const handleReasonConfirm = () => {
    console.log("delReason is: ", delReason);
    if (delReason === "discontinue") {
      setShowDiscontinueConfirmation(true);
      setShowDeleteReason(false);
    } else {
      setShowDeleteConfirmation(true);
      setShowDeleteReason(false);
    }
  }

  const toggleAddNewOn = () => {
    setAddNew(true);
    UpdatedBin = false;
  }

  return (
    <>
    <Stack spacing={2} position="relative">
      {ItemsDisplay.map((item: any, index: number) => (
        <MedsTradeLayout key={index} Bin={item} handleDelete={handleDeleteMedicine} setShowDeleteConfirmation={setShowDeleteConfirmation} setShowDeleteReason={setShowDeleteReason} setShowDiscontinueConfirmation={setShowDiscontinueConfirmation} setDelName={setDelName} onStateChange={onStateChange} setItemsDisplay={setItemsDisplay} setShowSuccessMessage={setShowSuccessMessage}/>
      ))}
       {
      AddNew ? (

        <MedsTradeEditableLayout Bin={{}} updateState={setItemsDisplay} handleClose={() => setAddNew(false)} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage}/>

      ) : (

       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {BinArray.length >= 3 && (
          <Button
            onClick={toggleExpanded}
            sx={{
              backgroundColor: '#E9E9E9',
              color: '#404B5A',
              width: '30%',
              paddingTop: '6px',
              paddingBottom: '6px',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            {expanded ? 'Collapse All' : 'Expand All'}
          </Button>
        )}
        <Button
        onClick={toggleAddNewOn}
          sx={{
            backgroundColor: '#E9F5FC',
            color: '#404B5A',
            width: '30%',
            paddingTop: '6px',
            paddingBottom: '6px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
          }}
        >
          + Add Medicine
        </Button>
      </div>

      )}

      {showDeleteConfirmation && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , position: "absolute",  top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, width: '100%', }} >
        <DeleteConfirmation handleDeleteClose={handleCancel} handleDeleteConfirm={handleDeleteConfirm} name={delName} />
        </div>
      )}

      {showDeleteReason && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , position: "absolute",  top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, width: '100%', }} >
        <DeleteReason handleDeleteClose={handleCancel} handleReasonConfirm={handleReasonConfirm} setDelReason={setDelReason} />
        </div>
      )}

      {showDiscotinueConfirmation && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , position: "absolute",  top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, width: '100%', }} >
        <DiscontinueReason handleDiscontinueClose={handleCancel} handleDiscontinueConfirm={handleDeleteConfirm} name={delName} setDelReason={setDelReason}/>
        </div>
      )}

      {showSuccessMessage && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , position: "absolute",  top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, width: '100%', }} >
        <SuccessMessage item="Medicine" Updated={UpdatedBin} setShowSuccessMessage={setShowSuccessMessage} />
        </div>
      )}

    </Stack>

   
</>
  );
}



export function MedsTradeEditableLayout({ Bin, updateState, handleClose, onStateChange, setShowSuccessMessage }: { Bin: any, updateState: any, handleClose: () => void;   onStateChange: (key: string, value: string, updateState?: any) => void; setShowSuccessMessage?: any }) {
  let keys = Object.keys(Bin);
  let values = Object.values(Bin);
  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );  
 
  const [numberValue, setNumberValue] = useState<string>(Bin[keys[2]]);
  const [tradeName, setTradeNameInput] = useState<string>(Bin[keys[0]]);
  const [salt, setSaltInput] = useState<string>(Bin[keys[1]]);
  const [unitValue, setUnitValue] = useState<string>(Bin[keys[3]]);
  const [frequencyValue, setFrequencyValue] = useState<string>(Bin[keys[4]]);
  const [routeValue, setRouteValue] = useState<string>(Bin[keys[5]]);
  const [prescribedBy, setPrescribedByInput] = useState<any>(currentPhysician);
  const [startDateValue, setStartDateValue] = useState<string>(Bin[keys[6]]);
  const [endDateValue, setEndDateValue] = useState<string>(Bin[keys[7]]);

  const handleTradeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTradeNameInput(event.target.value);
  };

  const handleSaltChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaltInput(event.target.value);
  };

  const handleNumberChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setNumberValue(value);
  };

  const handleUnitChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setUnitValue(value);
  };

  const handleRouteChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setRouteValue(value);
  };

  const handleFrequencyChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setFrequencyValue(value);
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setStartDateValue(value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEndDateValue(value);
  };

  const handlePrescribedByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrescribedByInput(event.target.value);
  };

  const handleSave = () => {
      // Add your save logic here
      const newState = {
        Trade_Name: tradeName || "",
        Salt: salt || "",
        Number: numberValue || "",
        Unit: unitValue || "",
        Route: routeValue || "",
        Frequency: frequencyValue || "",
        Start_date: startDateValue || "",
        End_date: endDateValue || "",
        Prescribed_by: prescribedBy || ""
      };
      const key: string = 'meds#'.concat(tradeName);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onStateChange(key, JSON.stringify(newState), updateState);
      handleClose();
  };

  const handleCloseClicked = () => {
    // Implement the logic
    handleClose();
  };


  return (
    <>
      {/* Heading and Subheading */}
      <Box sx={{ marginBottom: '0px' }}>
        <h2 style={{ margin: '0', fontSize: '13px', color: '#6c757d', fontWeight: 'normal' }}>Please add medicine details</h2>
      </Box>

      {/* Grey Box Container */}
      <Box sx={{ backgroundColor: '#F8F9FA', padding: '9px', borderRadius: '8px', border: '1px solid #D3D3D3', position: 'relative', paddingTop: '25px'}}>
        {/* Close Button */}
        <IconButton onClick={handleCloseClicked} sx={{ position: 'absolute', top: '8px', right: '8px', color: 'red', padding: '0', '& .MuiSvgIcon-root': { fontSize: '24px', }, }}>
          <CloseIcon />
        </IconButton>

        <InputField label="Trade Name" value={tradeName} onChange={handleTradeNameChange} required />
        <InputField label="Salt" value={salt} onChange={handleSaltChange} required />

        {/* Number and Unit in one row */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <DropdownField 
            label="Number" 
            value={numberValue} 
            onChange={handleNumberChange} 
            options={getFieldOptions('meds', 'Number')} 
            required 
            width="48%"
          />
          <DropdownField 
            label="Unit" 
            value={unitValue} 
            onChange={handleUnitChange} 
            options={getFieldOptions('meds', 'Unit')} 
            required 
            width="48%"
          />
        </Box>

        {/* Route and Frequency in one row */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <DropdownField 
            label="Route" 
            value={routeValue} 
            onChange={handleRouteChange} 
            options={getFieldOptions('meds', 'Route')} 
            required 
            width="48%"
          />
          <DropdownField 
            label="Frequency" 
            value={frequencyValue} 
            onChange={handleFrequencyChange} 
            options={getFieldOptions('meds', 'Frequency')} 
            required 
            width="48%"
          />
        </Box>

        {/* Start Date and End Date in one row */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <DateField 
            label="Start Date" 
            value={startDateValue} 
            onChange={handleStartDateChange} 
            required 
            width="48%"
          />
          <DateField 
            label="End Date" 
            value={endDateValue} 
            onChange={handleEndDateChange} 
            required 
            width="48%"
          />
        </Box>

        <InputField label="Prescribed By" value={prescribedBy} onChange={handlePrescribedByChange} required />

        <Button
          variant="contained"
          color="success"
          onClick={handleSave}
          sx={{
            borderRadius: '8px',
            marginTop: '16px',
            width: '100px', 
            height: '35px',
            marginLeft: 'auto', 
            display: 'block' 
          }}
        >
          Save
        </Button>
      </Box>
    </>
  );
}



//============================================================ALLERGIES BIN=================================================================================

export function AllergiesBinInActive({Bin}: AllergiesInActiveProps) {
  return (
    <>
  <Stack direction="column" justifyContent="space-between">
  <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
    <Stack>
    <Stack key="Bin" direction="row" alignItems="center" spacing={1}>
    <Typography component="div" variant="h6"  sx={{ color: 'Black', mr: 1 }}>
      Allergen
    </Typography>
    <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
      Allergen Type
    </Typography>
  </Stack>
  <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
    Reaction 
  </Typography>
  </Stack>
    <Stack>
  <Stack direction="row" alignItems="flex-start" spacing={3} sx={{mr: 1}}>
    <EventRepeatIcon sx={{ color: 'gray', fontSize: 21 }} />
    <EditIcon sx={{ color: 'gray', fontSize: 21 }} />
    <DeleteOutlineIcon sx={{ color: 'gray', fontSize: 21 }} />
  </Stack>
  <Typography component="span" sx={{ color: 'black', mr: 1, margin: 0, textAlign: 'right', display: 'block' }}>
    Category
  </Typography>
  </Stack>
  </Stack>
  {/* Reviewed By Stack: */}
  <Stack direction="row" justifyContent="space-between" >
  <Stack direction="row" alignItems="center">
      <Checkbox size="small" sx={{ p: 0, mr: 1, fontSize: 11 }} />
      <Typography component="span" sx={{ color: 'gray', fontSize: 15 }}>
        Mark as Reviewed
      </Typography>
    </Stack>
  <Typography component="span" sx={{ color: 'gray', mr: 0, fontSize: 14 }}>
    Last Reviewed By Dr. on Date
  </Typography>
  </Stack>
  </Stack>
  </>
  )

}


function AllergiesLayout({ Bin, handleDelete, setShowDeleteConfirmation, setDelName, onStateChange, setItemsDisplay, setShowSuccessMessage }: { Bin: MedsInActiveProps, handleDelete: (TradeName: string) => void, setShowDeleteConfirmation: (value: boolean) => void, setDelName: (value: string) => void, onStateChange: (key: string, value: string, updateState?: any) => void, setItemsDisplay: any, setShowSuccessMessage: any }): JSX.Element {
  let keys = Object.keys(Bin);
  let values = Object.values(Bin);
   

  const [DateClicked, setDateClicked] = useState(false);
  const [inEditing, setInEditing] = useState<boolean>(false);

  // console.log(`IN ALLERGIES BIN, BIN IS: ${Bin}`);
  
  let incomplete = false;

  for (let i = 0; i < values.length; i++) {
    if (values[i] === "" || values[i] === undefined) {
      incomplete = true;
      break;
    }
  }

  const toggleDateClicked = () => setDateClicked(!DateClicked);
  const toggleEditModeOn = () => setInEditing(true);
  const toggleEditModeOff = () => setInEditing(false);

  const handleDeleteClick = () => {
    setDelName(values[0])
    setShowDeleteConfirmation(true);
  } 

   const handleUpdateState = (key: string, value: string) => {
        // Example update function, can be customized
        console.log(`Updated ${key} to ${value}`);
      };
 
  return (
    <>

{inEditing ? (

<AllergiesEditableLayout  Bin={Bin} updateState={setItemsDisplay} handleClose={toggleEditModeOff} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage}  />

) : (


    <div style={{ backgroundColor: incomplete ? '#F8D7DA' : 'white', paddingInline: '5px', borderRadius: '8px', margin: 0, paddingTop: '15px', paddingBottom: '15px' }}>
  <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Stack>
    <Stack key="Bin" direction="row" alignItems="center" spacing={1}>
    <Typography component="div" variant="h6"  sx={{ color: 'Black', mr: 1 }}>
      {CheckTitle({Bin, keys, index: 0})}
    </Typography>
    <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
      {CheckTitle({Bin, keys, index: 1})}
    </Typography>
  </Stack>
  <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
    {CheckTitle({Bin, keys, index: 2})} 
  </Typography>
  </Stack>
    <Stack>
  <Stack direction="row" alignItems="flex-end" spacing={1} sx={{ ml: 'auto' }}>

    <IconButton
      onClick={toggleDateClicked}
      sx={{ color: 'gray' }} >
    <EventRepeatIcon sx={{ fontSize: 21 }} />
    </IconButton>


    <IconButton
      onClick={toggleEditModeOn}
      sx={{ color: 'gray' }}
    >
      <EditIcon sx={{ fontSize: 21 }} />
    </IconButton>



    <IconButton
      onClick={handleDeleteClick}
      sx={{ color: 'gray' }}
    >
      <DeleteOutlineIcon sx={{ fontSize: 21 }} />
    </IconButton>
  </Stack>
    {
      DateClicked ? (
        MedsDatesLayout({startDate: CheckTitle({Bin, keys, index: 4}), endDate: CheckTitle({Bin, keys, index: 5})})
      ) : (
        <Typography component="span" sx={{ color: 'black', mr: 1, margin: 0, textAlign: 'right', display: 'block' }}>
        {CheckTitle({Bin, keys, index: 3})} 
        </Typography>
      )
    }
  </Stack>
  </Stack>

  </div>
  )}
  </>
  )
}



export function AllergiesBinActive({ Bin, onStateChange, DeleteItemFromBin }: BinActiveProps) {
  // console.log(`[TESTING ALLERGIESSBIN] Bin in AllergiesBinActive: `, Bin);
  let BinArray = Array.isArray(Bin) ? Bin : [Bin];
  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  ); 

  const [expanded, setExpanded] = useState(false);
  const [AddNew, setAddNew] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  let topItems = expanded ? BinArray : BinArray.slice(0, 3);

  const [ItemsDisplay, setItemsDisplay] = useState(topItems);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [binUpdated, setBinUpdated] = useState(true);
  let UpdatedBin = true;

  const [delName, setDelName] = useState("");

  useEffect(() => {
    setItemsDisplay(topItems);
  }, [expanded]);

  //DeleteFunction for medicine:
  const handleDeleteAllergy = (AllergenName: string): any => {
    AllergenName = AllergenName.toString();
    const key: string = 'allergies#'.concat(AllergenName);
    DeleteItemFromBin(key, "", setItemsDisplay); // since no need to specify reason for this case
    BinArray = BinArray.filter((item: any) => item.Allergen !== AllergenName);
    topItems = expanded ? BinArray : BinArray.slice(0, 3);
    setItemsDisplay(topItems);
    console.log("Bin after deletion: ", JSON.stringify(BinArray, null, 2));
  };


  const handleCancel = () => {
    setShowDeleteConfirmation(false);
    setDelName("");
  }

  const handleDeleteConfirm = () => {
    console.log("Delete confirmed, now remove it from list");
    handleDeleteAllergy(delName);
    handleCancel();
  };

  const toggleAddNewOn = () => {
    setAddNew(true);
    setBinUpdated(false);
    UpdatedBin = false;
  }

  console.log("ItemsDisplay:", ItemsDisplay);

  return (
    <>
    <Stack spacing={2} position="relative">
      {ItemsDisplay.map((item: any, index: number) => (
        <AllergiesLayout key={index} Bin={item} handleDelete={handleDeleteAllergy} setShowDeleteConfirmation={setShowDeleteConfirmation}  setDelName={setDelName} onStateChange={onStateChange} setItemsDisplay={setItemsDisplay} setShowSuccessMessage={setShowSuccessMessage} />
      ))}
        { AddNew ? (

        <AllergiesEditableLayout Bin={{}} updateState={setItemsDisplay} handleClose={() => setAddNew(false)} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage} />

        ) : (
          <>
       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {Bin.length >= 3 && (
          <Button
            onClick={toggleExpanded}
            sx={{
              backgroundColor: '#E9E9E9',
              color: '#404B5A',
              width: '30%',
              paddingTop: '6px',
              paddingBottom: '6px',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            {expanded ? 'Collapse All' : 'Expand All'}
          </Button>
        )}
        <Button
        onClick={toggleAddNewOn}
          sx={{
            backgroundColor: '#E9F5FC',
            color: '#404B5A',
            width: '30%',
            paddingTop: '6px',
            paddingBottom: '6px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
          }}
        >
          + Add Allergy
        </Button>
      </div>

        <Stack direction="row" justifyContent="space-between" >
        <Stack direction="row" alignItems="center">
            <Checkbox sx={{ p: 0, mr: 1 }} />
            <Typography component="span" sx={{ color: 'gray', fontSize: 15 }}>
              Mark as Reviewed
            </Typography>
          </Stack>
        <Typography component="span" sx={{ color: 'gray', mr: 0, fontSize: 14 }}>
          Last Reviewed By Dr. {currentPhysician} on {new Date().toLocaleDateString()}
        </Typography>
        </Stack>

        </>
      

        )}

      {showDeleteConfirmation && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , position: "absolute",  top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, width: '100%', }} >
        <DeleteConfirmation handleDeleteClose={handleCancel} handleDeleteConfirm={handleDeleteConfirm} name={delName} />
        </div>
      )}

      {showSuccessMessage && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , position: "absolute",  top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, width: '100%', }} >
        <SuccessMessage item="Allergy" Updated={UpdatedBin} setShowSuccessMessage={setShowSuccessMessage} />
        </div>
      )}

    </Stack>
    </>
  );
}

export function AllergiesEditableLayout({ Bin, updateState, handleClose, onStateChange, setShowSuccessMessage }: { Bin: any, updateState: any, handleClose: () => void;   onStateChange: (key: string, value: string, updateState?: any) => void; setShowSuccessMessage?: any}) {
  let keys = Object.keys(Bin);
  let values = Object.values(Bin);
  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );  

  const [allergen, setAllergen] = useState<string>(Bin[keys[0]]);
  const [allergenType, setAllergenType] = useState<string>(Bin[keys[1]]);
  const [reaction, setReaction] = useState<string>(Bin[keys[2]]);
  const [category, setCategory] = useState<string>(Bin[keys[3]]);
  const [startDateValue, setStartDateValue] = useState<string>(Bin[keys[4]]);
  const [endDateValue, setEndDateValue] = useState<string>(Bin[keys[5]]);
  const [carryEpipen, setCarryEpipen] = useState<string>(Bin[keys[6]]);
  const [usedEpipen, setUsedEpipen] = useState<string>(Bin[keys[7]]);
  const [lastUsedEpipen, setLastUsedEpipen] = useState<string>(Bin[keys[8]]);
  const [additionalNotes, setAdditionalNotes] = useState<string>(Bin[keys[9]]);
  const [reviewedByInput, setReviewedByInput] = useState<string>(currentPhysician);
  


  const handleAllergenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllergen(event.target.value);
  };

  const handleAllergenTypeChange = (event: SelectChangeEvent<string>) => {
    setAllergenType(event.target.value);
  };

  const handleReactionChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setReaction(value);
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setCategory(value);
    
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setStartDateValue(value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEndDateValue(value);
  };

  const handleCarryEpipenChange = (event: SelectChangeEvent<string>) => {
    setCarryEpipen(event.target.value);
    if (event.target.value === "No") {
      setUsedEpipen("No");
      setLastUsedEpipen("1991-01-01");
    } 
  };


  const handleUsedEpipenChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setUsedEpipen(value);
    if (event.target.value === "No") {
      setLastUsedEpipen("1991-01-01");
    }
  };

  const handleLastUsedEpipenChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setLastUsedEpipen(value);
  };

  const handleAdditionalNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalNotes(event.target.value);
  }

  const handleReviewedByChange = () => {
    setReviewedByInput(currentPhysician);
  }



  const handleSave = () => {
    // Add your save logic here
    const newState = {
      Allergen: allergen || "",
      AllergenType: allergenType || "",
      Reaction: reaction || "",
      Category: category || "",
      StartDate: startDateValue || "",
      EndDate: endDateValue || "",
      CarryEpipen: carryEpipen || "",
      UsedEpipen: usedEpipen || "",
      LastUsedEpipen: lastUsedEpipen || "",
      AdditionalNotes: additionalNotes || "",
      ReviewedBy: reviewedByInput || ""
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const key: string = 'allergies#'.concat(allergen);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange(key, JSON.stringify(newState), updateState);
    setShowSuccessMessage(true);
    handleClose();
  };

  const handleCloseClicked = () => {
    // Implement the logic
    handleClose();
  };


  return (
    <>
      {/* Heading and Subheading */}
      <Box sx={{ marginBottom: '0px' }}>
        <h2 style={{ margin: '0', fontSize: '13px', color: '#6c757d', fontWeight: 'normal' }}>Please add allergy details</h2>
      </Box>

      {/* Grey Box Container */}
      <Box sx={{ backgroundColor: '#F8F9FA', padding: '9px', borderRadius: '8px', border: '1px solid #D3D3D3', position: 'relative', paddingTop: '25px'}}>
        {/* Close Button */}
        <IconButton onClick={handleCloseClicked} sx={{ position: 'absolute', top: '8px', right: '8px', color: 'red', padding: '0', '& .MuiSvgIcon-root': { fontSize: '24px', }, }}>
          <CloseIcon />
        </IconButton>

         <DropdownField 
            label="Category" 
            value={category} 
            onChange={handleCategoryChange} 
            options={getFieldOptions('allergies', 'Category')} 
            required 
            width="100%"
          />

        <InputField label="Allergen" value={allergen} onChange={handleAllergenChange} required />

        <DropdownField 
            label="Allergen Type" 
            value={allergenType} 
            onChange={handleAllergenTypeChange}
            options={getFieldOptions('allergies', 'Allergen_Type')} 
            required 
            width="100%"
          />

        {/* Start Date and End Date in one row */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <DateField 
            label="Start Date" 
            value={startDateValue} 
            onChange={handleStartDateChange} 
            required 
            width="48%"
          />
          <DateField 
            label="End Date" 
            value={endDateValue} 
            onChange={handleEndDateChange} 
            required 
            width="48%"
          />
        </Box>

        <DropdownField 
            label="Do you carry an Epipen?" 
            value={carryEpipen} 
            onChange={handleCarryEpipenChange} 
            options={getFieldOptions('allergies', 'Carry_Epipen')} 
            required 
            width="100%"
          />

        <DropdownField 
            label="Have you ever used an Epipen?" 
            value={usedEpipen} 
            onChange={handleUsedEpipenChange} 
            options={getFieldOptions('allergies', 'Used_Epipen')} 
            required 
            width="100%"
            sx={{
              opacity: carryEpipen === "No" ? 0.5 : 1,
              pointerEvents: carryEpipen === "No" ? 'none' : 'auto',
            }}  
          />

          <DateField 
            label="When was the last time you used an Epipen?"
            value={lastUsedEpipen}
            onChange={handleLastUsedEpipenChange} 
            required 
            width="100%"
            sx={{
              opacity: carryEpipen === "No" ? 0.5 : usedEpipen === "No" ? 0.5 : 1,
              pointerEvents: carryEpipen === "No" ? 'none' : usedEpipen === "No" ? "none" : 'auto',
            }}  
          />

          <DropdownField 
            label="Reaction" 
            value={reaction} 
            onChange={handleReactionChange} 
            options={getFieldOptions('allergies', 'Reaction')} 
            required 
            width="100%"  
          />

        <InputField label="Additional Notes" value={additionalNotes} onChange={handleAdditionalNotesChange} required />

        <InputField label="Reviewed By" value={reviewedByInput} onChange={handleReviewedByChange} required />

        <Button
          variant="contained"
          color="success"
          onClick={handleSave}
          sx={{
            borderRadius: '8px',
            marginTop: '16px',
            width: '100px', 
            height: '35px',
            marginLeft: 'auto', 
            display: 'block' 
          }}
        >
          Save
        </Button>
      </Box>
    </>
  );
}









// ======================================================================PAST MEDICAL HX BIN==================================================================================================

export function PastMedicalHxBinInActive({Bin}: any) {
  return (
    <>
  <Stack direction="column" justifyContent="space-between">
  <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
    <Stack key="Bin" direction="row" alignItems="center" spacing={1}>
    <Typography component="div" variant="h6"  sx={{ color: 'Black', mr: 1 }}>
      Disease
    </Typography>
    <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
      Specification
    </Typography>
  </Stack>
  <Stack direction="row" alignItems="flex-start" sx={{mr: 1}}>
  <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
    Date of Onset    
  </Typography>
  </Stack>
  </Stack>
  </Stack>
  </>
  )

}


function PastMedicalHxLayout({ Bin, onStateChange, setItemsDisplay, setShowSuccessMessage }: { Bin: MedsInActiveProps, onStateChange: (key: string, value: string, updateState?: any) => void, setItemsDisplay: any, setShowSuccessMessage: any }): JSX.Element {
  const keys = Object.keys(Bin) as Array<keyof typeof Bin>; 
  let values = Object.values(Bin);
  const DateOfOnset = Bin[keys[2]];
  // console.log(`Keys are: ${keys}`)

  const [OnsetDate, setOnsetDate] = useState<any>(DateOfOnset);
  const [DateClicked, setDateClicked] = useState(false);
  const [TagsClicked, setTagsClicked] = useState<boolean>(false);
   
  let incomplete = false;

  for (let i = 0; i < values.length; i++) {
    if (values[i] === "" || values[i] === undefined) {
      incomplete = true;
      break;
    }
  }

  const toggleDateClicked = () => setDateClicked(!DateClicked);

  const handleDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnsetDate(event.target.value);
  }

  const toggleTags = () => setTagsClicked(!TagsClicked);

return (
  <>
  <Stack direction="column" justifyContent="space-between">
  <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
    <div onClick={toggleTags} style={{ cursor: 'pointer'}}>
    <Stack key="Bin" direction="row" alignItems="center" spacing={1}>
      <Typography component="div" variant="h6"  sx={{ color: 'Black', mr: 1 }}>
        {CheckTitle({Bin, keys, index: 0})}
      </Typography>
      <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
        {CheckTitle({Bin, keys, index: 1})}
      </Typography>
    </Stack>
    </div>
  <Stack direction="row" alignItems="flex-start" sx={{mr: 1}}>
  <div onClick={toggleDateClicked} style={{ cursor: 'pointer'}}>
  <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
    Onset: {OnsetDate}  
  </Typography>
  </div>
  </Stack>
  </Stack>
  </Stack>

  {TagsClicked ? (
    <TagsLayout Bin={Bin} setItemsDisplay={setItemsDisplay} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage} />
  ) : (
    <></>
  )}

  {DateClicked ? (
    <DateField 
      label="Please select a date of Onset" 
      value={OnsetDate} 
      onChange={handleDateChanged} 
      required 
      width="95%"
    />
  ) : (
    <></>
 )}
  </>
)
}

function OrgansDropdown({ Bin }: any) {
  const keys = Object.keys(Bin);
  const [selectedOrgans, setSelectedOrgans] = useState<string[]>(["Affected Organs"]);

  const organOptions = ['Heart', 'Kidneys', 'Brain', 'Eyes', 'Liver', 'Feet'];

  const handleChange = (event: any) => {
    const value = event.target.value;
    setSelectedOrgans(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <div style={{
      padding: '5px 10px',
      borderRadius: '5px',
      marginTop: '10px',
      maxHeight: '40px',  // Adjusted maxHeight to 40px
      minWidth: '100px',
    }}>
      <FormControl 
        fullWidth 
        sx={{
          height: '40px',  // Set height to 40px
          backgroundColor: '#E1E7EC',
        }}>
        <Select
          labelId="organs-select-label"
          id="organs-select"
          multiple
          value={selectedOrgans || "Affected Organs"}
          onChange={handleChange}
          renderValue={(selected) => selected.join(', ')}
          sx={{
            height: '40px',  // Set height of the dropdown
            border: 'none',  // Remove the border
            '.MuiOutlinedInput-notchedOutline': { border: 0 },  // Remove default border
            padding: '0 10px',  // Adjust padding to fit the height
            backgroundColor: '#fff',  // Set background color to white
            '&:hover': {
              border: 'none',  // Ensure no borders on hover
            }
          }}
        >
          {organOptions.map((organ) => (
            <MenuItem key={organ} value={organ}>
              <Checkbox checked={selectedOrgans.indexOf(organ) > -1} />
              <ListItemText primary={organ} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}


function TagsLayout({ Bin, setItemsDisplay, onStateChange, setShowSuccessMessage }: any) {
  const keys = Object.keys(Bin);
  const PositiveTags = Bin[keys[3]];
  const NegativeTags = Bin[keys[4]];
  const NeutralTags = Bin[keys[5]];

  // State to track the hovered tag and its type (positive, negative, neutral)
  const [hoveredTag, setHoveredTag] = useState<{ tag: string, type: string } | null>(null);
  const [AddNewTag, setAddNewTag] = useState<boolean>(false);

  return (
    <div style={{ position: 'relative', padding: '10px', width: '100%' }}>
      {/* Tags container */}
      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', backgroundColor: '#F8F9FA', border: '1px solid #CCD4DB', padding: '20px' }}>
        {/* Positive Tags */}
        {PositiveTags.map((tag: string, index: number) => (
          <div
            key={`positive-${index}`}
            style={{
              backgroundColor: '#CCF1DE',
              padding: '5px 10px',
              borderRadius: '5px',
              color: '',
            }}
            onMouseEnter={() => setHoveredTag({ tag, type: 'positive' })}
            onMouseLeave={() => setHoveredTag(null)}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <img src={PositiveTagIcon} style={{ marginRight: 4, marginBottom: 0, width: 13, height: 13 }} />
              {tag}
            </Stack>
          </div>
        ))}

        {/* Negative Tags */}
        {NegativeTags.map((tag: string, index: number) => (
          <div
            key={`negative-${index}`}
            style={{
              backgroundColor: '#FCD9D9',
              padding: '5px 10px',
              borderRadius: '5px',
              color: '',
            }}
            onMouseEnter={() => setHoveredTag({ tag, type: 'negative' })}
            onMouseLeave={() => setHoveredTag(null)}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <img src={NegativeTagIcon} style={{ marginRight: 4, marginBottom: 0, width: 13, height: 13 }} />
              {tag}
            </Stack>
          </div>
        ))}

        {/* Neutral Tags */}
        {NeutralTags.map((tag: string, index: number) => (
          <div
            key={`neutral-${index}`}
            style={{
              backgroundColor: '#CDE7F6',
              padding: '5px 10px',
              borderRadius: '5px',
              color: '',
            }}
            onMouseEnter={() => setHoveredTag({ tag, type: 'neutral' })}
            onMouseLeave={() => setHoveredTag(null)}
          >
            {tag}
          </div>
        ))}

        {/* Organs Affected: */}
      <div>
      <OrgansDropdown Bin={Bin} />
      </div>

      <div
        style={{
          backgroundColor: '#E1E7EC',
          padding: '5px 50px',
          borderRadius: '5px',
          color: '',
          cursor: 'pointer',
        }}

        onClick={() => setAddNewTag(true)}
      >
        + 
      </div>
      

      </div>

      

      {/* Conditionally render TagKeywordsLayout beneath the hovered tag */}
      {hoveredTag && (
        <div style={{ marginTop: '20px' }}>
          <TagKeywordsLayout Bin={Bin} Tag={hoveredTag.tag} />
        </div>
      )}

      {/* Conditionally render PastMedicalAddNewTagLayout */}
      {AddNewTag && (
        <PastMedicalAddNewTagLayout Bin={Bin} updateState={setItemsDisplay} handleClose={() => setAddNewTag(false)} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage} />
      )}
    </div>
  );
}


function TagKeywordsLayout({ Bin, Tag }: any) {
  const keys = Object.keys(Bin);
  const AllKeywords = Bin[keys[6]];
  const TagKeywords = AllKeywords[Tag] || [];
  console.log(`TagKeywords: `, TagKeywords, "allKeywords: ", AllKeywords);

  return (
    <div style={{ position: 'relative', padding: '5px', width: '33%' }}>
      {/* Arrow pointing upwards */}
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          borderBottom: '10px solid #CCD4DB',
          position: 'absolute',
          top: 0,
          left: 20,
        }}
      />

      {/* Tags container */}
      <div
        style={{
          display: 'flex',
          gap: '3px',
          flexWrap: 'wrap',
          backgroundColor: '#F8F9FA',
          border: '1px solid #CCD4DB',
          padding: '5px',
        }}
      >
        {TagKeywords.length > 0 ? (
          <>
          <Typography component="span" sx={{ color: 'black', fontSize: 14 }}>
            Keywords:
          </Typography>
          {TagKeywords.map((keyword: string, index: number) => (
            <div
              key={`keyword-${index}`}
              style={{
                padding: '1px 2px',
                borderRadius: '5px',
                color: 'black',
              }}
            >
              {keyword}
            </div>
          ))}
          </>
        ) : (
          <div>No keywords found</div>
        )}
      </div>
    </div>
  );
}


function PastMedicalAddNewTagLayout({ Bin, updateState, handleClose, onStateChange, setShowSuccessMessage }: { Bin: any, updateState: any, handleClose: () => void;   onStateChange: (key: string, value: string, updateState?: any) => void; setShowSuccessMessage?: any }) {

  const [TagName, setTagName] = useState<string>("");
  const [Keywords, setKeywords] = useState<any>();
  const [color, setColor] = useState<string>("Blue");

  const keys = Object.keys(Bin);

  const [DiseaseName, setDiseaseName] = useState<any>(Bin[keys[0]]);
  const [Specification, setSpecification] = useState<any>(Bin[keys[1]]);
  const [DateOfOnset, setDateOfOnset] = useState<any>(Bin[keys[2]]);
  const [PositiveTags, setPositiveTags] = useState<any>(Bin[keys[3]]);
  const [NegativeTags, setNegativeTags] = useState<any>(Bin[keys[4]]);
  const [NeutralTags, setNeutralTags] = useState<any>(Bin[keys[5]]);
  const [TagKeywords, setTagKeywords] = useState<any>(Bin[keys[6]]);
  const [OrgansAffected, setOrgansAffected] = useState<any>(Bin[keys[7]]);




  const handleTagNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
  };

  const handleKeywordsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeywords(event.target.value);
  };

  const handleColorChange = (event: SelectChangeEvent<string>) => {
    setColor(event.target.value);
  };

  const handleSave = () => {

    if (color === "#CCF1DE") {
      setPositiveTags([...PositiveTags, TagName]);
    } else if (color === "#FCD9D9") {
      setNegativeTags([...NegativeTags, TagName]);
    } else {
      setNeutralTags([...NeutralTags, TagName]);
    }

    const newTagKeywords = {
      ...TagKeywords,
      [TagName]: Keywords.split(',').map((keyword: string) => keyword.trim()),
    };


    const newState = {
      Disease: DiseaseName || "",
      Specification: Specification || "",
      Onset: DateOfOnset || "",
      Positive_Tags: PositiveTags,
      Negative_Tags: NegativeTags,
      Neutral_Tags: NeutralTags,
      Tag_Keywords: newTagKeywords,
      Organs_Affected: OrgansAffected,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const key: string = 'pastMedicalHx#'.concat(DiseaseName);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange(key, JSON.stringify(newState), updateState);
    setShowSuccessMessage(true);
    handleClose();
  }

  const handleCloseClicked = () => {
    handleClose();
  }

  return (
    <>
       {/* Heading and Subheading */}
       <Box sx={{ marginBottom: '0px' }}>
        <h2 style={{ margin: '0', fontSize: '13px', color: '#6c757d', fontWeight: 'normal' }}>Please add Tag details</h2>
      </Box>

      {/* Grey Box Container */}
      <Box sx={{ backgroundColor: '#F8F9FA', padding: '9px', borderRadius: '8px', border: '1px solid #D3D3D3', position: 'relative', paddingTop: '25px'}}>
        {/* Close Button */}
        <IconButton onClick={handleCloseClicked} sx={{ position: 'absolute', top: '8px', right: '8px', color: 'red', padding: '0', '& .MuiSvgIcon-root': { fontSize: '24px', }, }}>
          <CloseIcon />
        </IconButton>

        <Stack direction="row" justifyContent="space-between">
        <div style={{ width: '70%' }}>
        <InputField label="Tag Name" value={TagName} onChange={handleTagNameChange} required />
        </div>

        <DropdownField 
            label="Color" 
            value={color} 
            onChange={handleColorChange}
            options={getFieldOptions('pastMedicalHx', 'TagColors')} 
            required 
            width="20%"
          />
        
        </Stack>

          <InputField label="Keywords (comma-separated)" value={Keywords} onChange={handleKeywordsChange} required />

        <Button
          variant="contained"
          color="success"
          onClick={handleSave}
          sx={{
            borderRadius: '8px',
            marginTop: '16px',
            width: '100px', 
            height: '35px',
            marginLeft: 'auto', 
            display: 'block' 
          }}
        >
          Add Tag
        </Button>
      </Box>


    </>
  )
 
}


function PastMedicalAddNewConditionLayout({ Bin, updateState, handleClose, onStateChange, setShowSuccessMessage }: { Bin: any, updateState: any, handleClose: () => void;   onStateChange: (key: string, value: string, updateState?: any) => void; setShowSuccessMessage?: any }) {

  const [DiseaseName, setDiseaseName] = useState<string>("");
  const [DateOfOnset, setDateOfOnset] = useState<any>();

  const handleDiseaseNameChange = (event: SelectChangeEvent<string>) => {
    setDiseaseName(event.target.value);
  };

  const handleDateOfOnsetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateOfOnset(event.target.value);
  };

  const handleSave = () => {
    const newState = {
      Disease: DiseaseName || "",
      Specification: "",
      Onset: DateOfOnset || "",
      Positive_Tags: [],
      Negative_Tags: [],
      Neutral_Tags: [],
      Tag_Keywords: {},
      Organs_Affected: [],
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const key: string = 'pastMedicalHx#'.concat(DiseaseName);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange(key, JSON.stringify(newState), updateState);
    setShowSuccessMessage(true);
    handleClose();
  }

  const handleCloseClicked = () => {
    handleClose();
  }

  return (
    <>
       {/* Heading and Subheading */}
       <Box sx={{ marginBottom: '0px' }}>
        <h2 style={{ margin: '0', fontSize: '13px', color: '#6c757d', fontWeight: 'normal' }}>Please add Disease details</h2>
      </Box>

      {/* Grey Box Container */}
      <Box sx={{ backgroundColor: '#F8F9FA', padding: '9px', borderRadius: '8px', border: '1px solid #D3D3D3', position: 'relative', paddingTop: '25px'}}>
        {/* Close Button */}
        <IconButton onClick={handleCloseClicked} sx={{ position: 'absolute', top: '8px', right: '8px', color: 'red', padding: '0', '& .MuiSvgIcon-root': { fontSize: '24px', }, }}>
          <CloseIcon />
        </IconButton>

         <DropdownField 
            label="Disease Name" 
            value={DiseaseName} 
            onChange={handleDiseaseNameChange} 
            options={getFieldOptions('pastMedicalHx', 'Disease')} 
            required 
            width="100%"
          />

        
          <DateField 
            label="Date of Onset"
            value={DateOfOnset}
            onChange={handleDateOfOnsetChange}
            required 
            width="100%"
          />

        
        <Button
          variant="contained"
          color="success"
          onClick={handleSave}
          sx={{
            borderRadius: '8px',
            marginTop: '16px',
            width: '100px', 
            height: '35px',
            marginLeft: 'auto', 
            display: 'block' 
          }}
        >
          Save
        </Button>
      </Box>


    </>
  )
}



export function PastMedicalHxBinActive({ Bin, onStateChange, DeleteItemFromBin }: BinActiveProps) {
  console.log(`[TESTING PastMedicalHx BIN] Bin in pmhBinActive: `, Bin);
  let BinArray = Array.isArray(Bin) ? Bin : [Bin];

  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  ); 

  const [expanded, setExpanded] = useState(false);
  const [AddNew, setAddNew] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  let topItems = expanded ? BinArray : BinArray.slice(0, 3);

  const [ItemsDisplay, setItemsDisplay] = useState(topItems);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  let UpdatedBin = true;

  const [delName, setDelName] = useState("");

  useEffect(() => {
    setItemsDisplay(topItems);
  }, [expanded]);


  const handleCancel = () => {
    setDelName("");
  }

  const handleDeleteConfirm = () => {
    console.log("Delete confirmed, now remove it from list");
    handleCancel();
  };

  const toggleAddNewOn = () => {
    setAddNew(true);
    UpdatedBin = false;
  }

  console.log("ItemsDisplay (in pmhactive):", ItemsDisplay);

  return (
    <>
    <Stack spacing={2} position="relative">
      {ItemsDisplay.map((item: any, index: number) => (
        <PastMedicalHxLayout key={index} Bin={item}  onStateChange={onStateChange} setItemsDisplay={setItemsDisplay} setShowSuccessMessage={setShowSuccessMessage} />
      ))}
        { AddNew ? (

        <PastMedicalAddNewConditionLayout Bin={{}} updateState={setItemsDisplay} handleClose={() => setAddNew(false)} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage} />

        ) : (
          <>
       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {Bin.length >= 3 && (
          <Button
            onClick={toggleExpanded}
            sx={{
              backgroundColor: '#E9E9E9',
              color: '#404B5A',
              width: '30%',
              paddingTop: '6px',
              paddingBottom: '6px',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            {expanded ? 'Collapse All' : 'Expand All'}
          </Button>
        )}
        <Button
        onClick={toggleAddNewOn}
          sx={{
            backgroundColor: '#E9F5FC',
            color: '#404B5A',
            width: '50%',
            paddingTop: '6px',
            paddingBottom: '6px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
          }}
        >
          + Add Medical Condition
        </Button>
      </div>
        </>
      

        )}

      {showSuccessMessage && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , position: "absolute",  top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, width: '100%', }} >
        <SuccessMessage item="Allergy" Updated={UpdatedBin} setShowSuccessMessage={setShowSuccessMessage} />
        </div>
      )}

    </Stack>
    </>
  );
}


//===========================================================Family Hx===========================================================================

function PatientStatusWidget() {
  const [status, setStatus] = useState('');

  const handleChange = (event: any) => {
    setStatus(event.target.value);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#F8F9FA',
        border: '1px solid #CCD4DB',
        padding: 2,
        borderRadius: 2,
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      <FormControl component="fieldset">
        <RadioGroup row value={status} onChange={handleChange}>
          <FormControlLabel
            value="adopted"
            control={<Radio />}
            label="Adopted"
          />
          <FormControlLabel
            value="no-significant-family-history"
            control={<Radio />}
            label="No Significant Family History"
          />
          <FormControlLabel
            value="unknown-family-history"
            control={<Radio />}
            label="Unknown Family History"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}


export function FamilyHxBinInActive({Bin}: any) {
  return (
    <>
    <PatientStatusWidget/>
  <Stack direction="column" justifyContent="space-between">
  <Typography component="span" sx={{ color: 'gray', mr: 1, fontWeight: "bold" }}>
      Patient's Relation
    </Typography>
  <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
    <Stack key="Bin" direction="column" alignItems="flex-start" spacing={1}>

    <Typography component="div" variant="h6"  sx={{ color: 'Black', mr: 1 }}>
      Disease
    </Typography>
    <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
      Additional Notes
    </Typography>
  </Stack>
  <Stack direction="row" alignItems="flex-start" sx={{mr: 1}}>
  <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
    Date of Onset    
  </Typography>
  </Stack>
  </Stack>
  </Stack>

    </>
  )
}


export function FamilyHxBinActive({ Bin, onStateChange, DeleteItemFromBin }: BinActiveProps) { 

return (
  <PatientStatusWidget/>
)

}






export default SocialHxBinInActive;

